import * as Yup from "yup";

export const adminLoginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),

  password: Yup.string().required("Please enter your password"),
});
