import { adminLoginSchema } from "../validations/Schema";

import {
  useNavigate,
  useLocation,
  useParams,
  Navigate,
  Outlet,
  Link,
} from "react-router-dom";

import DataService, {
  ImageURL,
  doAuthPost,
  doDelete,
  doDeleteBody,
  doGet,
  doGetParams,
  doPost,
  doPost2,
  doPostFormData,
  doPostFormDataModal,
  doUpdate,
  doGetQueryParams,
} from "../config/DataService";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../config/Api";

import { Formik, Form, Field, ErrorMessage } from "formik";

import { toast, ToastContainer } from "react-toastify";

import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Header from "../component/defaultLayout/Header";
import Sidebar from "../component/defaultLayout/Sidebar";

export default {
  adminLoginSchema,
  Svg,
  Png,
  Sidebar,
  Header,
  useDispatch,
  useSelector,
  toast,
  ToastContainer,

  ImageURL,
  doAuthPost,
  doDelete,
  doDeleteBody,
  doGet,
  doGetQueryParams,
  doGetParams,
  doPost,
  doPost2,
  doPostFormData,
  doPostFormDataModal,
  doUpdate,

  useNavigate,
  useLocation,
  useParams,
  Navigate,
  Outlet,
  Link,
  Api,
  DataService,

  Formik,
  Form,
  Field,
  ErrorMessage,
};
