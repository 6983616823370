import React from "react";
import Index from "../../../Index";
import { useEffect, useState } from "react";
import { useWeb3ModalSigner } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import abi from "./abi.json";
import presaleAbi from "./presaleAbi.json";

const PresaleActivity = () => {
  const [tokensData, setTokensData] = useState({
    currentPhasePrice: 0,
    nextPhasePrice: 0,
    tokenSold: 0,
    totalTokenSoldInPhases: 0,
    privateSalePhaseName: "",
    activePhase: "0",
    presaleIsActive: false,
  });
  const [tokonomicsData, setTokonomicsData] = useState([]);
  const [recepientAddress, setRecepientAddress] = useState("");
  const [recoverAmount, setRecoverAmount] = useState(0);
  const [recoverAddress, setRecoverAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState("");
  const { signer } = useWeb3ModalSigner();

  const contractAddress = "0xA9B925104342f2C248353cB69EEf538E8D810790";
  const pltTokenContractAddress = "0x361bE6f3035D3f3c7979a96db63137938C86f52d";
  const phase = ["Start Presale", "Active Phase 2", "Active Phase 3"];
  const allReadData = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://data-seed-prebsc-1-s1.binance.org:8545/"
    );
    const contract = new ethers.Contract(contractAddress, presaleAbi, provider);
    const plotopeContract = new ethers.Contract(
      pltTokenContractAddress,
      abi,
      provider
    );
    try {
      const getPhaseDetails = await contract.getPhasePricesAndTokens();

      setTokensData({
        currentPhasePrice: getPhaseDetails[0].toString() / 10000,
        nextPhasePrice: getPhaseDetails[1].toString() / 10000,
        tokenSold: getPhaseDetails[2].toString() / 10 ** 18,
        totalTokenSoldInPhases: getPhaseDetails[3].toString() / 10 ** 18,
        privateSalePhaseName: getPhaseDetails[4],
        activePhase: getPhaseDetails[5].toString(),
        presaleIsActive: getPhaseDetails[6],
      });

      const getTokonomicsDetails = await contract.getTokonomicsData();
      const price = await contract.getPhasePrice();
      const optimizedData = getTokonomicsDetails
        .slice(0, 3)
        .map((element, index) => {
          const totalRaise =
            (element[2].toString() / 10 ** 18) *
            (price[index]?.toNumber() || 0);
          return {
            OfferingType: element[0],
            Quantity: element[2].toString() / 10 ** 18,
            Price: (price[index]?.toNumber() || 0) / 10000,
            Raise: totalRaise / 10000,
          };
        });
      setTokonomicsData(optimizedData);
      const token = await plotopeContract.balanceOf(contractAddress);
      setTokenBalance(token.toString() / 10 ** 18);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const closePrivaseSaleStatus = async () => {
    const contract = new ethers.Contract(contractAddress, presaleAbi, signer);
    try {
      const tx = await contract.closePrivateSale();
      await tx.wait();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const startOrActivePhase = async (index) => {
    const contract = new ethers.Contract(contractAddress, presaleAbi, signer);
    try {
      if (index === 0) {
        const tx = await contract.startPrivateSale();
        await tx.wait();
      } else if (index === 1) {
        const tx = await contract.changePhaseStatus(index);
        await tx.wait();
      } else if (index === 2) {
        const tx = await contract.changePhaseStatus(index);
        await tx.wait();
      }
      allReadData();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const changeRecepientAddress = async () => {
    const contract = new ethers.Contract(contractAddress, presaleAbi, signer);
    try {
      const tx = await contract.changeRecipientAddress(recepientAddress);
      await tx.wait();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const recoverToken = async () => {
    console.log(recoverAmount, recoverAddress);
    const contract = new ethers.Contract(contractAddress, presaleAbi, signer);
    const weiValue = ethers.utils.parseEther(recoverAmount);
    try {
      const tx = await contract.recoverTokens(weiValue, recoverAddress);
      await tx.wait();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    allReadData();
  }, []);

  return (
    <>
      <Index.Box className="presale-wraper">
        <Index.Box className="res-cus-table">
          <Index.Box className="offering-cus-table">
            <Index.Box className="offering-table-head">
              {/* <Index.Typography component="p" variant='p' className='offering-th'></Index.Typography> */}
              <Index.Typography
                component="p"
                variant="p"
                className="offering-th th-blank"
              ></Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="offering-th th-offe-type"
              >
                Offering Type
              </Index.Typography>
              <Index.Box className="offering-list">
                <Index.Box className="inner-wrape">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th th-quantity"
                  >
                    Quantity
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th th-quantity th-offe-price"
                  >
                    Price
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th th-quantity th-offe-raise"
                  >
                    Raise
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th th-quantity th-action"
                  >
                    Action
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="offering-table-body">
              {tokonomicsData.map((data, index) => (
                <Index.Box className="offering-tr gradient-box">
                  <Index.Box className="offering-tr-main space-between gradient-inner-box">
                    {index <= tokensData.activePhase &&
                    tokensData.presaleIsActive === true ? (
                      <Index.Box className="th-blank">
                        {console.log("first")}
                        <Index.Box className="lock-main gradient-box">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <img
                              src={Index.Svg.padunlock}
                              alt="lock"
                              className="lock-ic"
                              style={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "lightgreen !important"
                                    : "white",
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ) : 2 === parseInt(tokensData.activePhase) &&
                    tokensData.presaleIsActive === false ? (
                      <Index.Box className="th-blank">
                        {console.log("first")}
                        <Index.Box className="lock-main gradient-box">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <img
                              src={Index.Svg.padunlock}
                              alt="lock"
                              className="lock-ic"
                              style={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "lightgreen !important"
                                    : "white",
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ): (
                      <Index.Box className="th-blank">
                        <Index.Box className="lock-main gradient-box">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <img
                              src={Index.Svg.padlock}
                              alt="lock"
                              className="lock-ic"
                              style={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "lightgreen !important"
                                    : "white",
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    )}
                    <Index.Box className="th-offe-type">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="offering-th-text offering-pre-seed"
                        sx={{
                          color:
                            data.OfferingType ===
                              tokensData.privateSalePhaseName &&
                            tokensData.presaleIsActive === true
                              ? "#18BF2E !important "
                              : "white",
                        }}
                      >
                        {data.OfferingType}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="offering-list d-flex">
                      <Index.Box className="inner-wrape">
                        <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text"
                              sx={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "#18BF2E !important "
                                    : "white",
                              }}
                            >
                              {Number(data.Quantity).toFixed(2)}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text"
                              sx={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "#18BF2E !important "
                                    : "white",
                              }}
                            >
                              ${data.Price}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text"
                              sx={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "#18BF2E !important "
                                    : "white",
                              }}
                            >
                              ${Number(data.Raise).toFixed(2)}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        {tokensData.presaleIsActive === false &&
                        parseInt(tokensData.activePhase) === 0 &&
                        parseInt(tokensData.activePhase) === index ? (
                          <Index.Box
                            className="offering-td-box quantity-box gradient-box th-quantity"
                            sx={{ cursor: "pointer" }}
                            onClick={() => startOrActivePhase(index)}
                          >
                            <Index.Box className="lock-ic-main cus-center ">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="offering-th-text"
                                sx={{
                                  color:
                                    data.OfferingType ===
                                      tokensData.privateSalePhaseName &&
                                    tokensData.presaleIsActive === true
                                      ? "#18BF2E !important "
                                      : "white",
                                }}
                              >
                                {phase[index]}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        ) : tokensData.presaleIsActive === true &&
                          index > parseInt(tokensData.activePhase) ? (
                          <Index.Box
                            className="offering-td-box quantity-box gradient-box th-quantity"
                            sx={{ cursor: "pointer" }}
                            onClick={() => startOrActivePhase(index)}
                          >
                            <Index.Box className="lock-ic-main cus-center gradient-inner-box btn-like">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="offering-th-text"
                                sx={{
                                  color:
                                    data.OfferingType ===
                                      tokensData.privateSalePhaseName &&
                                    tokensData.presaleIsActive === true
                                      ? "#18BF2E !important "
                                      : "white",
                                }}
                              >
                                {phase[index]}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        ) : index === parseInt(tokensData.activePhase) &&
                          tokensData.presaleIsActive === true ? (
                          <>
                            <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                              <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="offering-th-text"
                                  sx={{
                                    color:
                                      index <= tokensData.activePhase &&
                                      tokensData.presaleIsActive === true
                                        ? "#18BF2E !important "
                                        : "white",
                                  }}
                                >
                                  Ongoing
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </>
                        ) : index < parseInt(tokensData.activePhase) &&
                          tokensData.presaleIsActive === true ? (
                          <>
                            <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                              <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="offering-th-text"
                                  sx={{
                                    color:
                                      index <= tokensData.activePhase &&
                                      tokensData.presaleIsActive === true
                                        ? "white "
                                        : "white",
                                  }}
                                >
                                  Completed
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </>
                        ) : index <= parseInt(tokensData.activePhase) &&
                          tokensData.presaleIsActive === false ? (
                          <>
                            <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                              <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="offering-th-text"
                                  sx={{
                                    color:
                                      index <= tokensData.activePhase &&
                                      tokensData.presaleIsActive === true
                                        ? "white "
                                        : "white",
                                  }}
                                >
                                  Completed
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </>
                        ) : (
                          <>
                            <Index.Box className="offering-td-box quantity-box th-quantity"></Index.Box>
                          </>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              ))}
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="half-section">
          <Index.Grid container spacing={2}>
            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Change Recepient Address
                  </Index.Typography>
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        padding: 5,
                      },
                    }}
                    placeholder="0x..."
                    className="input-fild"
                    value={recepientAddress}
                    onChange={(e) => setRecepientAddress(e.target.value)}
                  ></Index.TextField>
                  <Index.PrimaryButton
                    onClick={changeRecepientAddress}
                    btnLabel="Change"
                    className="primary-btn"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Recover Tokens
                    <Index.Typography component="span" variant="span">
                      {tokenBalance} PLT{" "}
                    </Index.Typography>
                  </Index.Typography>
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        padding: 5,
                      },
                    }}
                    placeholder="Enter Amount"
                    className="input-fild"
                    value={recoverAmount}
                    onChange={(e) => setRecoverAmount(e.target.value)}
                  ></Index.TextField>
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        padding: 5,
                      },
                    }}
                    placeholder="0x..."
                    className="input-fild"
                    value={recoverAddress}
                    onChange={(e) => setRecoverAddress(e.target.value)}
                  ></Index.TextField>
                  <Index.PrimaryButton
                    onClick={recoverToken}
                    btnLabel="Recover"
                    className="primary-btn"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Close PrivateSale
                  </Index.Typography>
                  <Index.PrimaryButton
                    onClick={closePrivaseSaleStatus}
                    btnLabel="Change"
                    className="primary-btn"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default PresaleActivity;
