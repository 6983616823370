import { ToastContainer } from "react-toastify";

import Routes from "./routes/Routes";
import style from "./assets/style/admin.css";
import responsive from "./assets/style/responsive.css";
import './container/admin/pages/comingSoon/comingSoon.css'
import "react-toastify/dist/ReactToastify.css";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'

// 1. Get projectId
const projectId = '2d7ded4b2b1becfe9064b344d8c4ba63'

// 2. Set chains
const Goerli = {
  chainId: 5,
  name: 'Goerli',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://goerli.infura.io/v3/c699d17f7200444c8ffa9c281ed20452'
}

const BSCTestnet = {
  chainId: 97,
  name: 'BNB Testnet',
  currency: 'BNB',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
}

const Mumbai = {
  chainId: 80001,
  name: 'Mumbai',
  currency: 'MATIC',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://polygon-mumbai.infura.io/v3/c699d17f7200444c8ffa9c281ed20452'
}

// 3. Create modal
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com',
  icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [Goerli,BSCTestnet,Mumbai],
  projectId
})

function App() {
  {
    // console.log = () => {};
    // console.error = () => {};
    // console.debug = () => {};
  }

  return (
    <div className="App">
      <Routes />
      <ToastContainer />
    </div>
  );
}

export default App;
