import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import PageIndex from "../container/PageIndex";

const PrivateRoute = ({ children }) => {
  const location = PageIndex.useLocation();
  const UserLoggedIn = PageIndex.useSelector((state) => state.admin.isLogin);
  return UserLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace={true} />
  );
};

export default PrivateRoute;
