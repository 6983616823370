import React from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import Dashboard from "../container/admin/pages/dashboard/Dashboard";
import Home from "../container/admin/pages/home/Home";
import Login from "../container/admin/auth/Login";
import ComingSoon from "../container/admin/pages/comingSoon/ComingSoon";
import PrivateRoute from "./PrivateRoute";
import PresaleActivity from "../container/admin/pages/home/PresaleActivity";

const Routes = () => {
  return (
    <BrowserRouter>
      <Routess>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ComingSoon />} />

        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="" element={<Home />} />
            <Route path="/dashboard/presaleactivity" element={<PresaleActivity />} />
          </Route>
        </Route>
      </Routess>
    </BrowserRouter>
  );
};

export default Routes;
